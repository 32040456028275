.pointer{
  background: #ffffff99;
  border-radius: 50%;
  border: 1px solid #ccc;
}


/* Customize the Clustered Markers */
.marker-cluster-custom {
  background: #9370db;
  border: 3px solid #ededed;
  border-radius: 50%;
  color: #ededed;
  height: 40px;
  line-height: 37px;
  text-align: center;
  width: 40px;
}